export const archproject = [
  {
    id: 0,
    category: "arch",
    title: "Indy Street Flats",
    location: "Lakewood, CO",
    date: "2019",
    firm: "Shopworks Architecture",
    descShort:
      "Indy Flats is an affordable housing project that consisted of 3 remodeled apartment buildings, an adaptive reuse of the old Masonic Temple, and one ground up apartment building. The project provided 84 units in 5 buildings in the City of Lakewood.",
    descFull: "",
    urlMain: "",
    urlSub: "",
    caption: "photos by Matthew Staver",
    image1: require("../Assets/images/INDY_Exterior1.jpg").default,
    alt1: "project 1",
    image2: require("../Assets/images/INDY_Exterior2.jpg").default,
    alt2: "project 2",
    image3: require("../Assets/images/INDY_Interior.jpg").default,
    alt3: "project 3",
  },
  {
    id: 1,
    category: "arch",
    title: "Oil and Gas Company",
    location: "Denver, CO",
    date: "2008",
    firm: "Bothwell Davis George Architecture in conjunction w/Lake Flato Architects",
    descShort:
      "An adaptive reuse project in conjunction with Lake Flato Architects transformed the Denver Machine Shop into the headquarters for a local Oil and Gas company. Utlizing the shell of the original building and evoking the spirit of the machine shop, the new construction exudes an industrial vibe while providing function through the aesthetic form",
    descFull: "",
    urlMain: "",
    urlSub: "",
    caption: "photos by Frank Oohms",
    image1: require("../Assets/images/AOG_Exterior1.jpg").default,
    alt1: "project 1",
    image2: require("../Assets/images/AOG_Exterior2.jpg").default,
    alt2: "project 2",
    image3: require("../Assets/images/AOG_Interior1.jpg").default,
    alt3: "project 3",
  },
  {
    id: 2,
    category: "arch",
    title: "Eudora St. Residence",
    location: "Denver, CO",
    date: "2015",
    firm: "Nest Architectural Design",
    descShort:
      "New custom home for a homeowner client in Denver. The home utilized a number of specifically designed features that provide a clean aesthetic. Natural lighting flows through the house accentuating the textures provided by the contrasting surfaces throughout the space",
    descFull: "",
    urlMain: "",
    urlSub: "",
    caption: "photos by David Lauer",
    image1: require("../Assets/images/Eudora_Exterior1.jpg").default,
    alt1: "project 1",
    image2: require("../Assets/images/Eudora_Exterior2.jpg").default,
    alt2: "project 2",
    image3: require("../Assets/images/Eudora_Interior1.jpg").default,
    alt3: "project 3",
  },
];
