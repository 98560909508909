import React from "react";
import ImageCard from "../Imagecard";
import { thingsproject } from "../../utils/thingsproject";
import { Grid } from "semantic-ui-react";

function Things() {
  return (
    <>
      <h1 className="bodyHeader">other things</h1>
      <Grid>
        {thingsproject.map((project) => (
          <ImageCard
            key={project.id}
            title={project.title}
            caption={project.caption}
            image1={project.image1}
            alt1={project.alt1}
            className="imageGrid"
          />
        ))}
      </Grid>
    </>
  );
}

export default Things;
