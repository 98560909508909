import React from "react";
import { Grid } from "semantic-ui-react";

function ImageCard(props) {
  return (
    <>
      <Grid.Column mobile={16} tablet={10} computer={5}>
        <img src={props.image1} className="imageCard" alt={props.alt1} />
      </Grid.Column>
    </>
  );
}

export default ImageCard;
