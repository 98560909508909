export const thingsproject = [
  {
    id: 0,
    category: "things",
    title: "photography1",
    caption: "",
    image1: require("../Assets/images_2/IMG_1.jpg").default,
    alt1: "photography1",
  },
  {
    id: 1,
    category: "things",
    title: "photography2",
    caption: "",
    image1: require("../Assets/images_2/IMG_2.jpg").default,
    alt1: "photography2",
  },
  {
    id: 2,
    category: "things",
    title: "photography3",
    caption: "",
    image1: require("../Assets/images_2/IMG_3.jpg").default,
    alt1: "photography3",
  },
  {
    id: 3,
    category: "things",
    title: "photography4",
    caption: "",
    image1: require("../Assets/images_2/IMG_4.jpg").default,
    alt1: "photography4",
  },
  {
    id: 4,
    category: "things",
    title: "photography5",
    caption: "",
    image1: require("../Assets/images_2/IMG_5.jpg").default,
    alt1: "photography5",
  },
  {
    id: 5,
    category: "things",
    title: "photography6",
    caption: "",
    image1: require("../Assets/images_2/IMG_6.jpg").default,
    alt1: "photography6",
  },
  {
    id: 6,
    category: "things",
    title: "photography7",
    caption: "",
    image1: require("../Assets/images_2/IMG_7.jpg").default,
    alt1: "photography7",
  },
  {
    id: 7,
    category: "things",
    title: "photography8",
    caption: "",
    image1: require("../Assets/images_2/IMG_8.jpg").default,
    alt1: "photography8",
  },
  {
    id: 8,
    category: "things",
    title: "photography9",
    caption: "",
    image1: require("../Assets/images_2/IMG_9.jpg").default,
    alt1: "photography9",
  },
  {
    id: 9,
    category: "things",
    title: "photography10",
    caption: "",
    image1: require("../Assets/images_2/IMG_10.jpg").default,
    alt1: "photography10",
  },
  {
    id: 10,
    category: "things",
    title: "carcase saw 1",
    caption: "",
    image1: require("../Assets/images_2/Carcase_Saw1.jpg").default,
    alt1: "carcae saw 1",
  },
  {
    id: 11,
    category: "things",
    title: "carcase saw 2",
    caption: "",
    image1: require("../Assets/images_2/Carcase_Saw2.jpg").default,
    alt1: "carcase saw 2",
  },
  {
    id: 12,
    category: "things",
    title: "memory loom 1",
    caption: "",
    image1: require("../Assets/images_2/memLoom1.jpg").default,
    alt1: "memory loom 1",
  },
  {
    id: 13,
    category: "things",
    title: "memory loom 2",
    caption: "",
    image1: require("../Assets/images_2/memLoom2.jpg").default,
    alt1: "memory loom 2",
  },
  {
    id: 14,
    category: "things",
    title: "memory loom 3",
    caption: "",
    image1: require("../Assets/images_2/memLoom3.jpg").default,
    alt1: "memory loom 3",
  },
  {
    id: 15,
    category: "things",
    title: "bench 1",
    caption: "",
    image1: require("../Assets/images_2/Bench1.jpg").default,
    alt1: "bench 1",
  },
  {
    id: 16,
    category: "things",
    title: "bench 2",
    caption: "",
    image1: require("../Assets/images_2/Bench2.jpg").default,
    alt1: "bench 2",
  },
  {
    id: 17,
    category: "things",
    title: "bench 3",
    caption: "",
    image1: require("../Assets/images_2/Bench3.jpg").default,
    alt1: "bench 3",
  },
  {
    id: 18,
    category: "things",
    title: "bird haus 1",
    caption: "",
    image1: require("../Assets/images_2/birdHaus1.jpg").default,
    alt1: "bird haus 1",
  },
  {
    id: 19,
    category: "things",
    title: "bird haus 2",
    caption: "",
    image1: require("../Assets/images_2/birdHaus2.jpg").default,
    alt1: "bird haus 2",
  },
];
