import React from "react";
import { devProject } from "../../utils/devprojects";
import Rectcard from "../Rectcard";

function Dev() {
  return (
    <>
      <h1 className="bodyHeader">dev projects</h1>
      {devProject.map((project) => (
        <Rectcard
          key={project.id}
          title={project.title}
          descFull={project.descFull}
          urlMain={project.urlMain}
          urlSub={project.urlSub}
          image={project.image}
          iconMain={project.iconMain}
          alt={project.alt}
          languages={project.languages}
        />
      ))}
    </>
  );
}

export default Dev;
