import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Nav from "./components/Nav";
import Banner from "./components/Banner";
import Dev from "./components/pages/Dev";
import Arch from "./components/pages/Arch";
import Things from "./components/pages/Things";
import About from "./components/pages/About";
import Footer from "./components/Footer";
import "./App.css";

function App() {
  return (
    <Router>
      <Nav />
      <Banner />
      <Route exact path="/" component={Dev} />
      <Route exact path="/dev" component={Dev} />
      <Route exact path="/arch" component={Arch} />
      <Route exact path="/things" component={Things} />
      <Route exact path="/about" component={About} />
      <Footer />
    </Router>
  );
}

export default App;
