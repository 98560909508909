import React from "react";
import { Image } from "semantic-ui-react";
import { Container, Grid } from "semantic-ui-react";

function Rectcard(props) {
  return (
    <Container fluid className="rectContainer">
      <Grid columns={2}>
        <Grid.Row>
          <Grid.Column
            mobile={15}
            tablet={10}
            computer={6}
            className="imageCol"
            floated="right"
          >
            <a href={props.urlSub} target="#">
              <Image
                src={props.image}
                alt={props.alt}
                className="landscapeImage"
              />
            </a>
          </Grid.Column>
          <Grid.Column mobile={15} tablet={10} computer={6} className="copyCol">
            <h2>{props.title}</h2>
            <p>{props.descFull}</p>
            <p>{props.languages}</p>
            <a
              href={props.urlMain}
              target="#"
              className="linkGitHub"
              floated="left"
            >
              <img
                src={props.iconMain}
                className="octocatIcon"
                alt="github logo"
              />
            </a>
            <a href={props.urlMain} target="#" className="urlMainLink">
              {props.title}
            </a>
            <br />
            <br />
            <a href={props.urlSub} target="#" className="sublink">
              {props.urlSub}
            </a>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
}

export default Rectcard;
