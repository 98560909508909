import { React } from "react";
import { Link, useLocation } from "react-router-dom";
import { Container, Header, Menu } from "semantic-ui-react";

function Nav() {
  // const [active, setActive] = useState({ activeItem: "" });
  // const handleClick = (e, { name }) =>
  //   setActive({ ...active, activeItem: name });

  // Menu indicator, highlights active menu

  const location = useLocation();

  return (
    <Container fluid>
      <Menu text stackable className="navItem" size="massive" color="teal">
        <Header as="h1" position="left" className="navHeader">
          <Link to="/dev"> Sam Fan</Link>
        </Header>
        <Menu.Item position="right">
          <Link
            to="/dev"
            className={
              location.pathname === "/dev" ? "nav-link active" : "nav-link"
            }
          >
            Dev
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link
            to="/arch"
            className={
              location.pathname === "/arch" ? "nav-link active" : "nav-link"
            }
          >
            Arch
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link
            to="/things"
            className={
              location.pathname === "/things" ? "nav-link active" : "nav-link"
            }
          >
            Things
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link
            to="/about"
            className={
              location.pathname === "/about" ? "nav-link active" : "nav-link"
            }
          >
            About
          </Link>
        </Menu.Item>
        {/* <Menu.Item
          position="right"
          name="dev"
          active={active.activeItem === "dev"}
          content="dev"
          href="/dev"
          onClick={handleClick}
        />  
        <Menu.Item
          name="arch"
          active={active.activeItem === "arch"}
          content="arch"
          href="/arch"
          onClick={handleClick}
        />
        <Menu.Item
          name="things"
          active={active.activeItem === "things"}
          content="things"
          href="/things"
          onClick={handleClick}
        />
        <Menu.Item
          name="about"
          active={active.activeItem === "about"}
          content="about"
          href="about"
          onClick={handleClick}
        /> */}
      </Menu>
    </Container>
  );
}

export default Nav;
