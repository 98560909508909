import React from "react";
import LazyLoad from "react-lazyload";
import Squarecard from "../Squarecard";
import { archproject } from "../../utils/archproject";

function Arch() {
  return (
    <>
      <h1 className="bodyHeader">architecture projects</h1>
      <div className="list">
        <LazyLoad height={200}>
          {archproject.map((project) => (
            <Squarecard
              key={project.id}
              title={project.title}
              location={project.location}
              firm={project.firm}
              date={project.date}
              descShort={project.descShort}
              urlFirm={project.urlMain}
              urlPhoto={project.urlSub}
              caption={project.caption}
              image1={project.image1}
              image2={project.image2}
              image3={project.image3}
              alt1={project.alt1}
              alt2={project.alt2}
              alt3={project.alt3}
            />
          ))}
        </LazyLoad>
      </div>
    </>
  );
}

export default Arch;
