import React from "react";
import { Container, Grid } from "semantic-ui-react";
import Imagepopup1 from "./Imagepopup/Imagepopup1";
import Imagepopup2 from "./Imagepopup/Imagepopup2";
import Imagepopup3 from "./Imagepopup/Imagepopup3";

function Squarecard(props) {
  return (
    <Container fluid className="squareCard">
      <Grid columns={4}>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={8} computer={4}>
            <Imagepopup1 {...props} className="squareImage" />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={4}>
            <Imagepopup2 {...props} className="squareImage" />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={4}>
            <Imagepopup3 {...props} className="squareImage" />
          </Grid.Column>
          <Grid.Column className="projData" mobile={16} tablet={8} computer={4}>
            <h2>{props.title}</h2>
            <p className="projNotes">{props.location}</p>
            <p className="projNotes">Completed {props.date}</p>
            <p>{props.descShort}</p>
            <p className="projNotes">Completed while at {props.firm}</p>
            <p className="projNotes">{props.caption}</p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
}

export default Squarecard;
