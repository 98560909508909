export const devProject = [
  {
    id: 0,
    category: "dev",
    title: "Bean Feeder",
    languages:
      "MERN stack, MongoDB, Express, React, Javascript, Semantic UI React, CSS, Node",
    descFull:
      "Bean Feeder allows for a user to continue their coding journey in a distraction free environment, where they are able to add or remove videos about selected coding topics as they see fit, then they are able to view them in an embedded player.",
    urlMain: "https://github.com/SamFan808/BeanTeam",
    //urlSub: "https://feed-your-bean.herokuapp.com/",
    iconMain: require("../Assets/icons/octocat.svg").default,
    caption: "project",
    image: require("../Assets/images/Feed-Your-Bean.png").default,
    alt: "project",
    imageFeatured1: "../",
    imageFeatured2: "../",
  },
  {
    id: 1,
    category: "dev",
    title: "Chuck Roast",
    languages:
      "Javascript, jQuery, HTML, CSS, Foundation CSS framework, Meal DB, the Chuck Norris DB",
    descFull:
      "Chuck Roast mixes humour and meal ideas into one interactive app that allows a user to select a meal recipe based on an ingredient of their choice. A user can search recipes based on an array of pre-selected ingredients, or they can search for an ingredient of their choice.",
    urlMain: "https://github.com/SamFan808/Chuck_Roast",
    urlSub: "https://samfan808.github.io/Chuck_Roast/",
    iconMain: require("../Assets/icons/octocat.svg").default,
    caption: "project",
    image: require("../Assets/images/ChuckRoast.png").default,
    alt: "project",
    imageFeatured1: "../",
    imageFeatured2: "../",
  },
  {
    id: 2,
    category: "dev",
    title: "Discover Your Inner Animal",
    languages:
      "MySQL, Express, Express-Handlebars, Javascript, HTML, CSS, Materialize",
    descFull:
      "Discover Your Inner Animal is a quiz app that asks the user a series of questions to determine what their inner animal is. The user enters their answers and the answers are compared to a matrix to determine what animal lives within them.",
    urlMain: "https://github.com/SamFan808/discover-your-animal",
    //urlSub: "https://sleepy-sierra-89293.herokuapp.com/",
    iconMain: require("../Assets/icons/octocat.svg").default,
    caption: "project",
    image: require("../Assets/images/Discover-Your-Inner-Animal.png").default,
    alt: "project",
    imageFeatured1: "../",
    imageFeatured2: "../",
  },
  {
    id: 3,
    category: "dev",
    title: "Employours",
    languages: "MySQL, Javascript, NodeJS",
    descFull:
      "Employours is a command line application designed to allow an employer to enter employees, managers, their roles and salaries into a database for record keeping. Users can create, read, update, and delete employees.",
    urlMain: "https://github.com/SamFan808/Employours",
    //urlSub: "https://github.com/SamFan808/Employours",
    iconMain: require("../Assets/icons/octocat.svg").default,
    caption: "project",
    image: require("../Assets/images/Screenshot_hw_12-1.png").default,
    alt: "project",
    imageFeatured1: "../",
    imageFeatured2: "../",
  },
  {
    id: 4,
    category: "dev",
    title: "Budget Tracker",
    languages: "Javascript, NodeJS, Express, MongoDB, Mongoose, HTML, CSS",
    descFull:
      "Budget Tracker is an app which allows a user to enter deposits and expenditures to keep track of their spending, with the added functionality of being able to store data offline, and syncing once a connection has been restored.",
    urlMain: "https://github.com/SamFan808/budget-tracker",
    //urlSub: "https://shrouded-bayou-08519.herokuapp.com/",
    iconMain: require("../Assets/icons/octocat.svg").default,
    caption: "project",
    image: require("../Assets/images/Screenshot_hw_18.png").default,
    alt: "project",
    imageFeatured1: "../",
    imageFeatured2: "../",
  },
  // {
  //   id: 5,
  //   category: "dev",
  //   title: "Employours",
  //   languages: "MySQL, Javascript, Node",
  //   descFull:
  //     "Employours is a command line application designed to allow an employer to enter employees, managers, their roles and salaries into a database for record keeping. Users can create, read, update, and delete employees.",
  //   urlMain: "https://github.com/SamFan808/Employours",
  //   urlSub: "https://github.com/SamFan808/Employours",
  //   iconMain: require("../Assets/icons/octocat.svg").default,
  //   caption: "project",
  //   image: require("../Assets/images/Screenshot_hw_12-1.png").default,
  //   alt: "project",
  //   imageFeatured1: "../",
  //   imageFeatured2: "../",
  // },
];
