import React from "react";
import { Container, Image } from "semantic-ui-react";
//import { Button, Icon } from "semantic-ui-react;"
import linkedIn from "../Assets/icons/linkedin.svg";
import octocat from "../Assets/icons/octocat.svg";
//import resume from "../Assets/resume_fan2021.pdf";
//import resumeArch from "../Assets/resume_arch2022v2.pdf";

function Footer() {
  return (
    <footer>
      <Container className="cardFooter">
{/*         <div>
          <h3>Sam Fan</h3> <p>Denver, CO </p>
          <p>303.725.6329</p>
          <p> samisfan@msn.com</p>
        </div> */}
        <br />
{/*         <Button
          basic
          inverted
          icon
          size="medium"
          href={resume}
          className="resumeButton"
          download="resume_fan2021.pdf"
        >
          <Icon name="download" />
          Dev Resume
        </Button>
        <Button
          basic
          inverted
          icon
          size="medium"
          href={resumeArch}
          className="resumeButton"
          download="resume_arch2024.pdf"
        >
          <Icon name="download" />
          Arch Resume
        </Button> */}
        <br />
        <br />
        <a
          href="https://github.com/samfan808"
          target="#"
          className="linkGitHub"
        >
          <img src={octocat} className="octocatIcon" alt="github logo" />
        </a>
        <a
          href="https://www.linkedin.com/in/samfan808/"
          target="#"
          className="linkLinkedIn"
        >
          <Image src={linkedIn} className="linkedinIcon" alt="linkedin logo" />
        </a>
      </Container>
      <br />
      <p className="copyright">&copy; Copyright 2021-2024 Sam Fan</p>
    </footer>
  );
}

export default Footer;

// Image src={octocat} className="octocatIcon" alt="github logo"
