import React from "react";
import Popup from "reactjs-popup";
import { Image } from "semantic-ui-react";

function Imagepopup3(props) {
  return (
    <Popup
      trigger={<img src={props.image3} alt="project 3" className="popStyle" />}
      modal
      nested
    >
      {(close) => (
        <div className="modal">
          <button className="close" onClick={close}>
            &times;
          </button>
          <div className="header">{props.title}</div>
          <div className="content">
            <Image src={props.image3} alt={props.alt3} />
            <p>{props.caption}</p>
          </div>
        </div>
      )}
    </Popup>
  );
}

export default Imagepopup3;
