import React from "react";
import { Container, Grid, Image } from "semantic-ui-react";
import profile from "../../Assets/images/profile_crop.jpg";

function About() {
  return (
    <>
      <h1 className="bodyHeader">about me</h1>
      <Container fluid className="profile">
        <Grid>
          <Grid.Column mobile={16} tablet={8} computer={4}>
            <Image src={profile} className="profilePic" alt="profile"></Image>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={4}>
            <p className="profileCopy">
              Hi! I’m Sam, I live in Denver Colorado and I like to make things.
              I’m an architect, full stack web developer, and fan of making
              things. I am curious and tenacious. I want to know how things work
              and what makes them tick so I can understand them and make them
              better. Problem seeking and problem solving have been a part of me
              for as long as I can remember. Previously, I was an architect and
              understanding how things work and go together was paramount to
              making things work.
              <br />
              When I’m not coding, I like to spend time baking bread, cooking,
              making my family laugh, mountain biking, and building tiny
              keyboards.
            </p>
          </Grid.Column>
          <Grid.Column columns={1}></Grid.Column>
          <Grid.Column
            mobile={16}
            tablet={8}
            computer={4}
            className="devSkills"
          >
            <h2>dev skills:</h2>
            <h4>JavaScript</h4>
            <h4>HTML</h4>
            <h4>CSS</h4>
            <h4>Express</h4>
            <h4>Node.js</h4>
            <h4>React</h4>
            <h4>MongoDB</h4>
            <h4>MySQL</h4>
            <h4>GitHub</h4>
          </Grid.Column>

          <Grid.Column
            mobile={16}
            tablet={8}
            computer={3}
            className="addSkills"
          >
            <h2>other:</h2>
            <p>Adobe Photoshop</p>
            <p>Adobe Lightroom</p>
            <p>Adobe Illustrator</p>
            <p>Adobe InDesign</p>
            <p>Affinity Designer</p>
            <p>Revit</p>
            <p>AutoCad</p>
            <p>SketchUp</p>
          </Grid.Column>
        </Grid>
      </Container>
    </>
  );
}

export default About;
