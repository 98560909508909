import { React } from "react";
import { Container, Image } from "semantic-ui-react";
import bannerImage from "../Assets/banner_graphic.png";
// import { bannerQuote } from "../utils/bannerQuote";

function Banner() {
  // const [active, setActive] = useState({ activeItem: "" });

  // const [quote, setQuote] = useState();

  // const quoteHandler = () => {
  //   switch (bannerQuote.category) {
  //     case "featured":
  //       return bannerQuote.quote;
  //     case "dev":
  //       return bannerQuote.quote;
  //     case "arch":
  //       return bannerQuote.quote;
  //     case "things":
  //       return bannerQuote.quote;
  //     case "about":
  //       return bannerQuote.quote;
  //     default:
  //       return;
  //   }
  // };
  // rotating banner Text, updates to unique content per page

  return (
    <Container fluid>
      <header className="bannerText">
        <h2>Hi I'm Sam. I like to make things.</h2>
      </header>
      <Image src={bannerImage} className="bannerImage" alt="banner image" />
    </Container>
  );
}

export default Banner;
